import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../Components/Common/Header";
// import { Helmet } from "react-helmet";
export const Contact = () => {
  const [viewAllSelected, setViewAllSelected] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryselected, setcountryselected] = useState([]);
  const [programSelected, setProgramSelected] = useState([]);
  const [stateSelected, setStateSelected] = useState([]);
  const [States, setStates] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [successPopupVisible, setSuccessPopupVisible] = useState(false);
  const [showStateAndCity, setShowStateAndCity] = useState(false);
  const [isProgramSelected, setIsProgramSelected] = useState(false);
  // const [failurePopupVisible, setFailurePopupVisible] = useState(false);
  const [erpResponse, setErpResponse] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [submitButtonText, setSubmitButtonText] = useState("Submit enquiry");
  const [captchamessage, setCaptchamessage] = useState(false);
  const [tncChecked, setTncChecked] = useState(true);
  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 10) + 1;
  };

  const checkCaptchaAnswer = (num1, num2, userAnswer) => {
    const correctAnswer = num1 + num2;
    const parsedUserAnswer = parseInt(userAnswer, 10);
    return parsedUserAnswer === correctAnswer;
  };
  const [num1, setNum1] = useState(generateRandomNumber());
  const [num2, setNum2] = useState(generateRandomNumber());
  const [userAnswer, setUserAnswer] = useState("");
  const [isCaptchaCorrect, setIsCaptchaCorrect] = useState(false);
  // const generateRandomNumber = () => {
  //   return Math.floor(Math.random() * 10);
  // };
  useEffect(() => {
    setNum1(generateRandomNumber());
    setNum2(generateRandomNumber());
    setUserAnswer("");
  }, []);

  const [formValues, setFormValues] = useState({
    City: "",
    CourseId: "",
    CourseType: "",
    Country: "",
    Email: "",
    FacultyId: 1,
    FirstName: "",
    InstituteId: 12,
    LandingPage: "https://admissions.mgmu.ac.in/",
    LastName: "",
    LeadSource: "",
    Phone: "",
    state: "",
    Type: 1,
  });
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  useEffect(() => {
    filterPrograms();
  }, [formValues.CourseType, viewAllSelected]);
  useEffect(() => {
    if (States.length === 0) {
      fetchStates();
    }
  }, []);
  useEffect(() => {
    fetchPrograms();
  }, []);
  useEffect(() => {
    if (States.length === 0) {
      fetchCountries();
    }
  }, []);
  const findCountryNameById = (countryId) => {
    const selectedCountry = countries.find(
      (country) => country.CountryId === parseInt(countryId)
    );
    return selectedCountry ? selectedCountry.CountryName : "";
  };

  const findProgramById = (programId) => {
    const selectedProgram = programs.find(
      (program) => program.ProgramId === parseInt(programId)
    );
    return selectedProgram
      ? {
          ErpProgramId: selectedProgram.ErpProgramId,
          CourseTypeName: selectedProgram.CourseTypeName,
        }
      : { ErpProgramId: "", CourseTypeName: "" };
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name === "Country") {
      // Update selected country
      setcountryselected(findCountryNameById(value));
      // Update filtered states based on selected country
      const selectedCountryId = parseInt(value);
      const filteredStates = States.filter(
        (state) => state.CountryId === selectedCountryId
      );
      setFilteredStates(filteredStates);
    }
    if (name === "CourseType") {
      // console.log("Selected course type:", value);

      filterPrograms();
    }
    if (name === "program") {
      // console.log("Selected program type:", findProgramNameById(value));
      setProgramSelected(findProgramById(value));
    }
    if (name === "State") {
      setStateSelected(value); // Update the selected state
    }
  };
  const fetchCountries = async () => {
    try {
      const response = await fetch(
        "https://admissions.mgmu.ac.in/api/Masters/fetchCountriesNStates/?Action=1&GeoFetch=1"
      );
      if (response.ok) {
        const data = await response.json();
        const CountryData = data.Data.Records;
        // console.log(CountryData);
        setCountries(CountryData);
      } else {
        throw new Error("Error fetching countries");
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchStates = async () => {
    try {
      const response = await fetch(
        "https://admissions.mgmu.ac.in/api/Masters/fetchCountriesNStates/?Action=2&GeoFetch=0"
      );
      if (response.ok) {
        const data = await response.json();
        const stateData = data.Data.Records;
        // console.log(stateData);
        setStates(stateData);
      } else {
        throw new Error("Error fetching countries");
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const filterStates = () => {
    if (formValues.Country !== "") {
      const selectedCountryId = parseInt(formValues.Country);
      // console.log(selectedCountryId, "selectedCountryId");
      const filteredStates = States.filter(
        (state) => state.CountryId === selectedCountryId
      );
      setFilteredStates(filteredStates);
    } else {
      setFilteredStates([]); // Reset filtered states if no Country is selected
    }
  };
  // console.log(filteredStates, "filteredStates");
  const handleCountrySelect = () => {
    if (countries.length !== 0) {
      // fetchCountries();
      setShowStateAndCity(true);
    }
    //  console.log("Bhakti");
  };
  const handleStateSelect = () => {
    filterStates();
  };
  const fetchPrograms = async () => {
    try {
      const response = await fetch(
        "https://admissions.mgmu.ac.in/api/Programs/fetchPrograms/?InstituteId=12"
      );
      if (response.ok) {
        const data = await response.json();
        const programData = data.Data.Records;
        setPrograms(programData);
      } else {
        throw new Error("Error fetching data");
      }
    } catch (error) {
      console.error("Error fetching programs:", error);
    }
  };
  const handleCourseTypeSelect = () => {
    if (formValues.CourseType !== "") {
      if (formValues.CourseType === "view-all") {
        // User selected "view-all", so show all programs without filtering
        setViewAllSelected(true);
        setFilteredPrograms(programs); // Display all programs
      } else {
        // User selected a specific CourseType (UG, PG, Diploma), filter programs
        setViewAllSelected(false);
        const filteredPrograms = programs.filter(
          (program) => program.CourseTypeName === formValues.CourseType
        );
        setFilteredPrograms(filteredPrograms); // Display filtered programs
      }
    }
  };

  const filterPrograms = () => {
    if (formValues.CourseType !== "") {
      if (viewAllSelected) {
        const filteredPrograms = programs.filter((program) =>
          [235, 237, 239].includes(program.ProgramId)
        );
        setFilteredPrograms(filteredPrograms);
      } else {
        const filteredPrograms = programs.filter(
          (program) => program.CourseType === formValues.CourseType
        );
        setFilteredPrograms(filteredPrograms);
      }
    } else {
      setFilteredPrograms([]);
    }
  };

  const handleProgramSelect = () => {
    handleCourseTypeSelect();
  };
  const handleProgramSelecting = () => {
    setIsProgramSelected(true);
  };

  // console.log(programSelected,"programSelectedprogramSelectedprogramSelectedprogramSelected")
  const showPrograms = formValues.CourseType !== "";
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prepare the data to be sent
    setSubmitButtonText("Submitting...");

    const postData = {
      City: formValues.City,
      CourseId: programSelected.ErpProgramId,
      CourseType: programSelected.CourseTypeName,
      Country: countryselected,
      Email: formValues.Email,
      FacultyId: 1,
      FirstName: formValues.FirstName,
      InstituteId: 12,
      LandingPage: "https://admissions.mgmu.ac.in/",
      LastName: formValues.LastName,
      LeadSource: null,
      Phone: formValues.Phone,
      State: stateSelected,
      Type: 1,
    };
    const isAnswerCorrect = checkCaptchaAnswer(num1, num2, userAnswer);
    setIsCaptchaCorrect(isAnswerCorrect);
    if (isAnswerCorrect && tncChecked) {
      setCaptchamessage(false);
      try {
        const response = await fetch(
          "https://admissions.mgmu.ac.in/api/Enquiry/saveEnquiry/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          // setSuccessModalVisible(true);
          console.log("Enquiry submitted successfully:", responseData.Message);
          // console.log(
          //   "Enquiry submitted successfully:",
          //   responseData.Data.ErpResponse[0].applicationURL
          // );
          setErrorMessage(responseData.Message);
          setErpResponse(responseData.Data.ErpResponse[0].applicationURL);
          setSuccessPopupVisible(true);
          // setFailurePopupVisible(false);
        } else {
          // setFailureModalVisible(true);
          console.log(
            "Enquiry submission failed with status:",
            response.status
          );
          setSuccessPopupVisible(false);
          // setFailurePopupVisible(false);
        }
      } catch (error) {
        console.error("Error while submitting enquiry:", error);
        // setFailurePopupVisible(true);
      }
      console.log("Captcha solved correctly!");
    } else {
      // Handle incorrect answer case
      setCaptchamessage(true);
      console.log("Captcha solved incorrectly!");
      console.log(isCaptchaCorrect);
    }
    // console.log(postData, "submitted data");

    setSubmitButtonText("Submit enquiry");
  };
  return (
    <>
      {/* <Helmet>
        <title>My Awesome Page</title>
        <meta name="description" content="Welcome to my awesome page!" />
      
      </Helmet> */}
      <Header />
      <section id="contact" className="contact-us-area">
        <div className="container pt-150">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 col-md-8">
              <div className="ha-enquiry-block" id="dvEnquiryBlock">
                <div className="ha-enquiry-block-inner">
                  <h2 className="ha-enquiry-block-heading">
                    Enroll for MGM's School of Film Arts
                  </h2>
                  <p className="ha-enquiry-block-subheading">
                    Please fill in the form below and our admission counsellor
                    will provide you with all necessary information and
                    assistance.
                  </p>
                  <form
                    className="ha-enquiry-form animate__animated animate__fadeInUp"
                    id="haEnquiryForm"
                    onSubmit={handleSubmit}
                    data-parsley-validate
                  >
                    <div id="formResponse">
                      {errorMessage && (
                        <div className="alert alert-danger fadeIn animated">
                          <i className="icon ion-information-circled"></i>{" "}
                          Error! {errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="txtEF1Name"
                            name="FirstName"
                            placeholder="First name"
                            value={formValues.FirstName}
                            onChange={handleChange}
                            minLength="3"
                            maxLength="60"
                            style={{ textTransform: "capitalize" }}
                            data-parsley-error-message="Please enter a valid name"
                            required
                          />
                          <label htmlFor="txtEF1Name">First Name</label>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="txtEF2Name"
                            name="LastName"
                            placeholder="Last name"
                            value={formValues.LastName}
                            onChange={handleChange}
                            minLength="3"
                            maxLength="60"
                            style={{ textTransform: "capitalize" }}
                            data-parsley-error-message="Please enter a valid name"
                            required
                          />
                          <label htmlFor="txtEF2Name">Last Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <select
                        className="form-select  "
                        id="  "
                        name="Country"
                        placeholder=""
                        onChange={handleChange}
                        data-parsley-error-message="Please select your Country"
                        required
                        value={formValues.Country}
                        onClick={handleCountrySelect}
                      >
                        <option defaultValue="">Select Country</option>
                        {countries &&
                          countries.map((Country, index) => (
                            <option
                              key={`${Country.CountryId}-${index}`}
                              value={Country.CountryId}
                            >
                              {Country.CountryName}
                            </option>
                          ))}
                      </select>
                      <label htmlFor="  ">My Country</label>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        {showStateAndCity && (
                          <div className="form-floating mb-3">
                            <select
                              className="form-select ddlEFStates"
                              id="ddlEF1States"
                              name="State"
                              placeholder=""
                              onChange={handleChange}
                              onClick={handleStateSelect}
                              data-parsley-error-message="Please select your state"
                              required
                            >
                              <option defaultValue="">Select state</option>
                              {filteredStates &&
                                filteredStates.map((state, index) => (
                                  <option
                                    key={`${state.StateId}-${index}`}
                                    value={state.StateName}
                                  >
                                    {state.StateName}
                                  </option>
                                ))}
                            </select>
                            <label htmlFor="ddlEF1States">My state</label>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 col-12">
                        {showStateAndCity && (
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="txtEF1City"
                              name="City"
                              placeholder="City / Village"
                              value={formValues.City}
                              onChange={handleChange}
                              maxLength="60"
                              style={{ textTransform: "capitalize" }}
                              data-parsley-error-message="Please enter a valid City name"
                              required
                            />
                            <label htmlFor="txtEF1City">City / Village</label>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="tel"
                        className="form-control PxtEFPhone"
                        id="PxtEF1Phone"
                        name="Phone"
                        placeholder="My Phone no."
                        value={formValues.Phone}
                        onChange={handleChange}
                        minLength="10"
                        maxLength="10"
                        pattern="[0-9]*"
                        data-parsley-error-message="Please enter a valid 10 digit Phone number"
                        required
                      />
                      <label htmlFor="PxtEF1Phone">My Phone no.</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="Email"
                        className="form-control"
                        id="txtEF1Email"
                        name="Email"
                        placeholder="My Email address"
                        value={formValues.Email}
                        onChange={handleChange}
                        minLength="3"
                        maxLength="60"
                        style={{ textTransform: "capitalize" }}
                        data-parsley-type="Email"
                        data-parsley-error-message="Please enter a valid Email address"
                        required
                      />
                      <label htmlFor="txtEF1Email">My Email address</label>
                    </div>
                    <div className="form-floating mb-3">
                      <select
                        className="form-select ddlCourseType"
                        id="ddlEF1CourseType"
                        name="CourseType"
                        placeholder="I am looking for programs in"
                        onChange={handleChange}
                        required
                        value={formValues.CourseType}
                        data-parsley-error-message="Please select a course type to proceed"
                        onClick={handleProgramSelect}
                      >
                        <option defaultValue="">Course category</option>
                        <option value="view-all">- View all</option>
                        <option value="Undergraduate (UG)">
                          Undergraduate (UG)
                        </option>
                        <option value="Postgraduate (PG)">
                          Postgraduate (PG)
                        </option>
                        <option value="Diploma">Diploma</option>
                      </select>
                      <label htmlFor="ddlEF1CourseType">
                        I am looking for programs in
                      </label>
                    </div>
                    {showPrograms && (
                      <div
                        className="form-floating mb-3"
                        style={{ display: showPrograms ? "block" : "none" }}
                      >
                        <select
                          className="form-select ddlPrograms"
                          id="ddlEF1Programs"
                          name="program"
                          placeholder="I want to enroll in"
                          data-parsley-error-message="Please select a program"
                          required
                          onClick={handleProgramSelecting}
                          onChange={handleChange}
                          value={formValues.program}
                        >
                          <option defaultValue="">Choose a Program</option>
                          {filteredPrograms &&
                            filteredPrograms.map((program) => (
                              <option
                                key={program.ProgramId}
                                value={program.ProgramId}
                              >
                                {program.ProgramName}
                              </option>
                            ))}
                        </select>
                        <label htmlFor="ddlEF1Programs">
                          I want to enroll in
                        </label>
                      </div>
                    )}
                    {isProgramSelected && showPrograms && (
                      <div className="arithmetic-captcha mb-3">
                        <label className="form-arithmetic-captcha-label mb-2">
                          Captcha
                          <br />
                          <span>Please solve the mathematical problem</span>
                        </label>
                        <div className="row">
                          <div className="col-4">
                            <div className="captcha-keyword">
                              {num1} + {num2}
                            </div>
                          </div>
                          <div className="col-8">
                            <input
                              type="hidden"
                              id="txtArCaptchaAnswer"
                              value="0"
                            />
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                id="txtEF1ArCaptcha"
                                placeholder="Your answer"
                                value={userAnswer}
                                onChange={(e) => setUserAnswer(e.target.value)}
                                minLength="1"
                                maxLength="2"
                                pattern="[0-9]*"
                                data-parsley-minlength="1"
                                data-parsley-maxlength="2"
                                data-parsley-type="number"
                                data-parsley-error-message="Please solve the maths equation shown above"
                                data-parsley-equalto="#txtArCaptchaAnswer"
                                required
                              />
                              <label htmlFor="txtEF1ArCaptcha">
                                Your answer
                              </label>
                            </div>
                          </div>
                          {captchamessage && (
                            <p className="captcha-error-message">
                              Please solve the maths equation shown above
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="d-grid gap-2 mb-3">
                      <button
                        type="submit"
                        className="btn btn-theme-primary btn-form-submit"
                        disabled={submitButtonText === "Submitting..."} // Disable the button during form submission
                      >
                        {submitButtonText}
                      </button>
                    </div>{" "}
                    <div className="form-check tnc-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        checked={tncChecked}
                        id="chkTNCAccept"
                        onChange={(e) => setTncChecked(e.target.checked)} // Update the state when the checkbox is checked or unchecked
                        data-parsley-mincheck="1"
                        name="chk-tnc"
                        data-parsley-error-message="Please accept to the service terms & conditions"
                        required
                      />
                      <label className="form-check-label" for="chkTNCAccept">
                        I authorize MGM University to update me via Call, Email,
                        SMS & WhatsApp. This would override registry on DND /
                        NDNC.
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {successPopupVisible && (
        <div className="popup success-popup">
          <div className="container">
            <div className="header__inner-2"></div>
            <div className="header__logo-2 mgmu-logo">
              <img
                style={{ width: "150px" }}
                src="https://filmmaking.mgmu.ac.in/assets/imgs/logo/logo-mgm.svg"
                alt="MGMU Logo"
              />
            </div>
            <div className="etp-text mt-5">
              <h3>
                Thank you, <br />
                <span>
                  {formValues.FirstName}
                  {formValues.LastName}!
                </span>
                <br />
                for showing interest to learn at MGM University : School Of Film
                And Arts.
              </h3>
              <p className="mt-5">
                Your enquiry has been successfully received &amp; also we have
                initiated the application process for you, for more details on
                the same please check your email. To continue with the
                application process press the button below
              </p>
              <Link to={erpResponse} className="btn btn-theme-primary mt-3">
                Resume with application process
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
