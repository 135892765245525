import React, { useEffect, useState } from "react";
import "../../assets/css/headerFooter.css";
import { Link, useLocation } from "react-router-dom";
import LogoWhiteSVG from "../../assets/imgs/logo/site-logo-white-2.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
export const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isVisible, setIsVisible] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 0 ? false : true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setIsVisible(true);
  }, []);
  useEffect(() => {
    const updateMenuText = () => {
      const buttons = document.querySelectorAll(".menu-anim > li > a");
      buttons.forEach((button) => {
        button.innerHTML =
          '<div className="menu-text"><span>' +
          button.textContent.split("").join("</span><span>") +
          "</span></div>";
      });

      const menuText = document.querySelectorAll(".menu-text span");
      menuText.forEach((item) => {
        const fontSizes = window.getComputedStyle(item, null);
        let fontSize = fontSizes.getPropertyValue("font-size");
        let sizeInNumber = parseInt(fontSize.replace("px", ""));
        let newSize = parseInt(sizeInNumber / 3);
        newSize = newSize + "px";
        if (item.innerHTML === " ") {
          item.style.width = newSize;
        }
      });
    };

    setTimeout(updateMenuText, 1000);
  }, []);
  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const [openMenus, setOpenMenus] = useState([]);
  const handleAccordionClick = (index) => {
    setOpenMenus((prevOpenMenus) => {
      if (prevOpenMenus.includes(index)) {
        return prevOpenMenus.filter((itemIndex) => itemIndex !== index);
      } else {
        return [...prevOpenMenus, index];
      }
    });
  };
  const isAboutUsActive = [
    "/about-mgm-university",
    "/our-pillars",
    "/about-mgm-film-arts",
    "/faculty",
  ].some((link) => activeLink.startsWith(link));
  return (
    <>
      <Link to="/contact-us" id="scroll_top" className="scroll-top">
        Apply Now
      </Link>
      <Offcanvas className="off-canvas" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="sidebar">
            <ul className="sidebar-menu">
              {/* hOME */}
              <li className={`main-menu-4 menu-anim  pagenav `}>
                <Link onClick={() => handleAccordionClick(1)} to="/">
                  HOME
                </Link>
              </li>
              {/* aBOUT uS */}
              <li className={`pagenav ${openMenus.includes(2) ? "open" : ""}`}>
                <Link to="#">ABOUT US</Link>
                <i
                  onClick={() => handleAccordionClick(2)}
                  className={`icons fa ${
                    openMenus.includes(2) ? "fa-minus" : "fa-plus"
                  }`}
                  style={{ right: "0px" }}
                ></i>{" "}
              </li>
              <ul
                className="sub-menu-list sidebar-sub-menu"
                style={{
                  maxHeight: openMenus.includes(2) ? "200px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease-in-out", // Adjust the transition timing here
                }}
              >
                <li className="page_item">
                  <Link to="/about-mgm-university">MGM UNIVERSITY</Link>
                </li>
                <li className="page_item">
                  <Link to="/our-pillars">OUR PILLARS</Link>
                </li>
                <li className="page_item">
                  <Link to="/about-mgm-film-arts">About MGM Film Arts</Link>
                </li>
                <li className="page_item">
                  <Link to="/faculty">Faculty</Link>
                </li>
              </ul>

              {/* aCADEMICS */}
              <li className={`pagenav ${openMenus.includes(3) ? "open" : ""}`}>
                <Link to="#">Academics</Link>
                <i
                  onClick={() => handleAccordionClick(3)}
                  className={`icons fa ${
                    openMenus.includes(3) ? "fa-minus" : "fa-plus"
                  }`}
                ></i>
              </li>
              <ul
                className="sidebar-sub-menu"
                style={{
                  maxHeight:
                    openMenus.includes(3) &&
                    !openMenus.includes(4) &&
                    !openMenus.includes(5)
                      ? "260px"
                      : openMenus.includes(3)
                      ? "640px"
                      : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease-in-out",
                }}
              >
                {/* Sub-menu items */}
                <li
                  className={`page_item ${openMenus.includes(4) ? "open" : ""}`}
                >
                  <Link to="#">Programs</Link>
                  <i
                    className={`icons fa ${
                      openMenus.includes(4) ? "fa-minus" : "fa-plus"
                    }`}
                    onClick={() => handleAccordionClick(4)}
                  ></i>
                </li>
                <ul
                  className="sub-menu-list"
                  style={{
                    maxHeight: openMenus.includes(4) ? "250px" : "0",
                    overflow: "hidden",
                    transition: "max-height 0.5s ease-in-out",
                  }}
                >
                  {/* Dropdown items */}

                  <li className="page_item">
                    <Link to="/certificate-program-2">
                      DIPLOMA IN FILMMAKING
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link to="/graduation-program">
                      UNDER GRADUATION IN FILMMAKING
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link to="/ma-in-filmmaking">
                      POST GRADUATION IN FILMMAKING
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link to="/certificate-programs-in-film-making">
                      SHORT TERM PROGRAMS
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link to="/admission-procedure">ADMISSION PROCEDURE</Link>
                  </li>
                </ul>

                <li
                  className={`page_item ${openMenus.includes(5) ? "open" : ""}`}
                >
                  <Link to="#">Specialisation</Link>
                  <i
                    className={`icons fa ${
                      openMenus.includes(5) ? "fa-minus" : "fa-plus"
                    }`}
                    onClick={() => handleAccordionClick(5)}
                  ></i>
                </li>
                <ul
                  className="sub-menu-list"
                  style={{
                    maxHeight: openMenus.includes(5) ? "350px" : "0",
                    overflow: "hidden",
                    transition: "max-height 0.5s ease-in-out",
                  }}
                >
                  {/* Dropdown items */}
                  <li className="page_item">
                    <Link to="/acting-for-films">Acting For Films</Link>
                  </li>
                  <li className="page_item">
                    <Link to="/cinematography">Cinematography & DI</Link>
                  </li>
                  <li className="page_item">
                    <Link to="/film-direction">
                      Film Direction & Screenwriting
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link to="/film-editing">Film Editing & DI</Link>
                  </li>
                  <li className="page_item">
                    <Link to="/production-design-art-direction">
                      Production Design & Art Direction
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link to="/sound-design-music-production">
                      Sound Design & Music Production
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link to="/vfx-animation">VFX & Animation</Link>
                  </li>
                </ul>
              </ul>

              {/* aCTIVITIES */}
              <li className={`pagenav ${openMenus.includes(6) ? "open" : ""}`}>
                <Link to="#">Activities</Link>
                <i
                  onClick={() => handleAccordionClick(6)}
                  className={`icons fa ${
                    openMenus.includes(6) ? "fa-minus" : "fa-plus"
                  }`}
                  style={{ right: "0px" }}
                ></i>{" "}
              </li>
              <ul
                className="sub-menu-list sidebar-sub-menu"
                style={{
                  maxHeight: openMenus.includes(6) ? "490px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease-in-out", // Adjust the transition timing here
                }}
              >
                <li className="page_item">
                  <Link to="/film-festival-attendance">
                    FILM FESTIVAL ATTENDENCE
                  </Link>
                </li>
                <li className="page_item">
                  <Link to="/gallery">GALLERY</Link>
                </li>
                <li className="page_item">
                  <Link to="/audition">AUDITION</Link>
                </li>
                <li className="page_item">
                  <Link to="/mgm-university-film-society">
                    MGM FILM SOCIETY
                  </Link>
                </li>
                {/* <li className="page_item">
                  <Link to="#">CAMPUS LIFE</Link>
                </li> */}
                {/* nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn */}

                <li
                  className={`page_item ${openMenus.includes(8) ? "open" : ""}`}
                >
                  <Link to="#">CAMPUS LIFE</Link>
                  <i
                    className={`icons fa ${
                      openMenus.includes(8) ? "fa-minus" : "fa-plus"
                    }`}
                    onClick={() => handleAccordionClick(8)}
                    style={{ height: "44px" }}
                  ></i>
                </li>
                <ul
                  className="sub-menu-list"
                  style={{
                    maxHeight: openMenus.includes(8) ? "250px" : "0",
                    overflow: "hidden",
                    transition: "max-height 0.5s ease-in-out",
                  }}
                >
                  {/* Dropdown items */}

                  <li className="page_item">
                    <Link
                      style={{ marginLeft: "10%" }}
                      to="/certificate-program-2"
                    >
                      DIPLOMA IN FILMMAKING
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link
                      style={{ marginLeft: "10%" }}
                      to="/graduation-program"
                    >
                      UNDER GRADUATION IN FILMMAKING
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link style={{ marginLeft: "10%" }} to="/ma-in-filmmaking">
                      POST GRADUATION IN FILMMAKING
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link
                      style={{ marginLeft: "10%" }}
                      to="/certificate-programs-in-film-making"
                    >
                      SHORT TERM PROGRAMS
                    </Link>
                  </li>
                  <li className="page_item">
                    <Link
                      style={{ marginLeft: "10%" }}
                      to="/admission-procedure"
                    >
                      ADMISSION PROCEDURE
                    </Link>
                  </li>
                </ul>
                {/* nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn */}

                <li className="page_item">
                  <Link to="/events">EVENTS</Link>
                </li>
                <li className="page_item">
                  <Link to="/film-productions">FILM PRODUCTIONS</Link>
                </li>
                <li className="page_item">
                  <Link to="/methodology">METHODOLOGY</Link>
                </li>
                <li className="page_item">
                  <Link to="#">STUDENT'S PROJECTS & AWARDS</Link>
                </li>
              </ul>

              {/* fACILITIES */}
              <li className={`main-menu-4 menu-anim  pagenav `}>
                <Link to="/facilities">Facilities</Link>
              </li>

              {/* fTTI cOURSES*/}
              <li className={`pagenav ${openMenus.includes(7) ? "open" : ""}`}>
                <Link to="/ftii-courses">ftii courses</Link>
                <i
                  onClick={() => handleAccordionClick(7)}
                  className={`icons fa ${
                    openMenus.includes(7) ? "fa-minus" : "fa-plus"
                  }`}
                  style={{ right: "0px" }}
                ></i>
              </li>
              <ul
                className="sub-menu-list sidebar-sub-menu"
                style={{
                  maxHeight: openMenus.includes(7) ? "260px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease-in-out",
                }}
              >
                <li className="page_item">
                  <Link to="#">Film Appreciation Course</Link>
                </li>
                <li className="page_item">
                  <Link to="/mobile-journalism">Smart Phone Journalism</Link>
                </li>
                <li className="page_item ">
                  <Link to="/visual-storytelling">Visual Storytelling</Link>
                </li>
                <li className="page_item ">
                  <Link to="/digital-film-production">
                    Digital Film Production
                  </Link>
                </li>
                <li className="page_item ">
                  <Link to="/screen-acting">Screen Acting</Link>
                </li>
              </ul>

              {/* cONTACT uS*/}
              <li
                className={`main-menu-4 menu-anim  pagenav ${
                  openMenus.includes(1) ? "open" : ""
                }`}
              >
                <Link onClick={() => handleAccordionClick(1)} to="/contact-us">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <header
        className={`header__area-7 ${
          isMobileMenuOpen ? "mobile-menu-open" : ""
        }`}
      >
        <div className={`contact-area ${isVisible ? "visible" : ""}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <ul className="contact-details">
                  <li>
                    <a href="mailto:hodfilmarts@mgmu.ac.in" rel="_blank">
                      hodfilmarts@mgmu.ac.in
                    </a>
                  </li>
                  <li>
                    <a href="tel:02406481052">02406481052</a>
                  </li>
                </ul>
              </div>
              <div className="col-6">
                <ul className="social-links text-end">
                  <li>Follow us</li>
                  <li>
                    <a href="https://www.facebook.com/mgmfilmarts">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/mgmfilmarts">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/c/MGMFilmArts">
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/mgm-film-arts">
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/mgmfilmarts">
                      <i className="fa-brands fa-square-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="header__inner-2">
            <div className={"header__logo-2 mgmu-logo justify-content-end"}>
              <Link to="/">
                <img
                  src="https://shaats.net/logos/logo-mgm.svg"
                  alt="MGMU Logo"
                />
              </Link>
            </div>
            <div className="header__nav-2">
              <ul className="main-menu-4 menu-anim text-center">
                <li className="">
                  <Link
                    className={activeLink === "/" ? "Linkactive-color" : ""}
                    to="/"
                  >
                    HOME
                  </Link>
                </li>
                <li
                  className={
                    activeLink === "/facilities" ? "HeadLinkactive" : ""
                  }
                >
                  <Link>ABOUT US</Link>
                  <ul className="main-dropdown">
                    <li
                      className={
                        activeLink === "/about-mgm-university"
                          ? "Linkactive"
                          : ""
                      }
                    >
                      <Link to="/about-mgm-university">MGM UNIVERSITY</Link>
                    </li>
                    <li
                      className={
                        activeLink === "/our-pillars" ? "Linkactive" : ""
                      }
                    >
                      <Link to="/our-pillars">OUR PILLARS</Link>
                    </li>
                    <li
                      className={
                        activeLink === "/about-mgm-film-arts"
                          ? "Linkactive"
                          : ""
                      }
                    >
                      <Link to="/about-mgm-film-arts">ABOUT MGM FILM ARTS</Link>
                    </li>
                    <li
                      className={activeLink === "/faculty" ? "Linkactive" : ""}
                    >
                      <Link to="/faculty">FACULTY</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#">ACADEMICS</Link>
                  <ul className="main-dropdown">
                    <li>
                      <Link to="#">PROGRAMS</Link>
                      <ul className="sub-dropdown">
                        <li
                          className={
                            activeLink === "/certificate-program-2"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/certificate-program-2">
                            DIPLOMA IN FILMMAKING
                          </Link>
                        </li>
                        <li
                          className={
                            activeLink === "/graduation-program"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/graduation-program">
                            UNDER GRADUATION IN FILMMAKING
                          </Link>
                        </li>
                        <li
                          className={
                            activeLink === "/ma-in-filmmaking"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/ma-in-filmmaking">
                            POST GRADUATION IN FILMMAKING
                          </Link>
                        </li>
                        <li
                          className={
                            activeLink ===
                            "/certificate-programs-in-film-making"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/certificate-programs-in-film-making">
                            SHORT TERM PROGRAMS
                          </Link>
                        </li>
                        <li
                          className={
                            activeLink === "/admission-procedure"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/admission-procedure">
                            ADMISSION PROCEDURE
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#">SPECIALIZATIONS</Link>
                      <ul className="sub-dropdown">
                        <li
                          className={
                            activeLink === "/acting-for-films"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/acting-for-films">Acting For Films</Link>
                        </li>
                        <li
                          className={
                            activeLink === "/cinematography" ? "Linkactive" : ""
                          }
                        >
                          <Link to="/cinematography">
                            Cinematography &amp; DI
                          </Link>
                        </li>
                        <li
                          className={
                            activeLink === "/film-direction" ? "Linkactive" : ""
                          }
                        >
                          <Link to="/film-direction">
                            Film Direction &amp; Screenwriting
                          </Link>
                        </li>
                        <li
                          className={
                            activeLink === "/film-editing" ? "Linkactive" : ""
                          }
                        >
                          <Link to="/film-editing">Film Editing &amp; DI</Link>
                        </li>
                        <li
                          className={
                            activeLink === "/production-design-art-direction"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/production-design-art-direction">
                            Production Design &amp; Art Direction
                          </Link>
                        </li>
                        <li
                          className={
                            activeLink === "/sound-design-music-production"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/sound-design-music-production">
                            Sound Design &amp; Music Production
                          </Link>
                        </li>
                        <li
                          className={
                            activeLink === "/vfx-animation" ? "Linkactive" : ""
                          }
                        >
                          <Link to="/vfx-animation">VFX &amp; Animation</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#">ACTIVITIES</Link>
                  <ul className="main-dropdown">
                    <li
                      className={
                        activeLink === "/film-festival-attendance"
                          ? "Linkactive"
                          : ""
                      }
                    >
                      <Link to="/film-festival-attendance">
                        FILM FESTIVAL ATTENDENCE
                      </Link>
                    </li>
                    <li
                      className={activeLink === "/gallery" ? "Linkactive" : ""}
                    >
                      <Link to="/gallery">GALLERY</Link>
                    </li>

                    <li
                      className={activeLink === "/audition" ? "Linkactive" : ""}
                    >
                      <Link to="/audition">AUDITION</Link>
                    </li>
                    <li
                      className={
                        activeLink === "/mgm-university-film-society"
                          ? "Linkactive"
                          : ""
                      }
                    >
                      <Link to="/mgm-university-film-society">
                        MGM FILM SOCIETY
                      </Link>
                    </li>
                    <li>
                      <Link to="#">CAMPUS LIFE</Link>
                      <ul className="sub-dropdown">
                        <li
                          className={
                            activeLink === "/stalwarts-about-mgm-film-arts"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/stalwarts-about-mgm-film-arts">
                            STALWARTS ABOUT MGM FILM ARTS
                          </Link>
                        </li>
                        <li
                          className={
                            activeLink === "/placements" ? "Linkactive" : ""
                          }
                        >
                          <Link to="/placements">PLACEMENTS</Link>
                        </li>
                        <li
                          className={
                            activeLink === "/masterclass" ? "Linkactive" : ""
                          }
                        >
                          <Link to="/masterclass">MASTERCLASS</Link>
                        </li>

                        <li
                          className={
                            activeLink === "/student-reviews"
                              ? "Linkactive"
                              : ""
                          }
                        >
                          <Link to="/student-reviews">STUDENT REVIEWS</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={activeLink === "/events" ? "Linkactive" : ""}
                    >
                      <Link to="/events">EVENTS</Link>
                    </li>
                    <li
                      className={
                        activeLink === "/film-productions" ? "Linkactive" : ""
                      }
                    >
                      <Link to="/film-productions">FILM PRODUCTIONS</Link>
                    </li>
                    <li
                      className={
                        activeLink === "/methodology" ? "Linkactive" : ""
                      }
                    >
                      <Link to="/methodology">METHODOLOGY</Link>
                    </li>
                    <li>
                      <Link to="#">STUDENT'S PROJECTS &amp; AWARDS</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    className={
                      activeLink === "/facilities" ? "Linkactive-color" : ""
                    }
                    to="/facilities"
                  >
                    FACILITIES
                  </Link>
                </li>
                <li>
                  <Link to="#">FTII COURSES</Link>
                  <ul className="main-dropdown">
                    <li
                      className={
                        activeLink === "/ftii-courses" ? "Linkactive" : ""
                      }
                    >
                      <Link to="/ftii-courses">FILM APPRECIATION COURSE</Link>
                    </li>
                    <li
                      className={
                        activeLink === "/mobile-journalism" ? "Linkactive" : ""
                      }
                    >
                      <Link to="/mobile-journalism">
                        SMART PHONE JOURNALISM
                      </Link>
                    </li>
                    <li
                      className={
                        activeLink === "/visual-storytelling"
                          ? "Linkactive"
                          : ""
                      }
                    >
                      <Link to="/visual-storytelling">VISUAL STORYTELLING</Link>
                    </li>
                    <li
                      className={
                        activeLink === "/digital-film-production"
                          ? "Linkactive"
                          : ""
                      }
                    >
                      <Link to="/digital-film-production">
                        DIGITAL FILM PRODUCTION
                      </Link>
                    </li>
                    <li
                      className={
                        activeLink === "/screen-acting" ? "Linkactive" : ""
                      }
                    >
                      <Link to="/screen-acting">SCREEN ACTING</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="https://mgmu.ac.in/alumni-meet-2024/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ALUMNI MEET
                  </a>
                </li>
                <li>
                  <Link
                    className={
                      activeLink === "/contact-us" ? "Linkactive-color" : ""
                    }
                    to="/contact-us"
                  >
                    CONTACT US
                  </Link>
                </li>
              </ul>
            </div>
            <div className="header__logo-2">
              <Link to="/" className="logo-dark">
                <img
                  src={require("../../assets/imgs/logo/logo-black.svg")}
                  alt="Site Logo"
                />{" "}
                <main>
                  <section className="job__detail-top pt-150 pb-100">
                    <div className="container">
                      <div className="row">
                        <div className="col-xxl-12">
                          <div className="sec-title-wrapper">
                            <h2 className="sec-title animation__char_come text-white mt-3">
                              Screen Acting
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
              </Link>
              <Link to="/" className="logo-light">
                <img src={LogoWhiteSVG} alt="Site Logo" />
              </Link>
            </div>
            <div className="header__nav-icon-7 d-lg-block d-xl-none">
              <button
                className={`menu-icon-2 ${isMobileMenuOpen ? "open" : ""}`}
                onClick={handleMobileMenuToggle}
                id="open_offcanvas"
              >
                <img
                  type="button"
                  className={`menu-dark`}
                  src={require("../../assets/imgs/icon/menu-dark.png")}
                  alt="Menubar Icon"
                  onClick={handleShow}
                />
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </header>
    </>
  );
};
