import React from "react";
import Img1 from "../../../assets/imgs/team/1.jpg";
import Img2 from "../../../assets/imgs/team/2.jpg";
// import Img3 from "../../../assets/imgs/team/3.jpg";
import Img4 from "../../../assets/imgs/team/4.jpg";
import Img5 from "../../../assets/imgs/team/5.jpg";
import Img6 from "../../../assets/imgs/team/6.jpg";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Reveal } from "react-reveal";
export const Faculty = () => {
  const facultyMembers = [
    {
      imgSrc: Img1,
      name: "Prof. Shiv Kadam (HOD)",
      designation: "(Faculty Of Film Director)",
    },
    // {
    //   imgSrc: Img3,
    //   name: "Prof. Jaee Kadam",
    //   designation: "(Faculty of Cinematography)",
    // },
    {
      imgSrc: Img2,
      name: "Prof. Dr. Yogita Mahajan",
      designation: "(Faculty of film acting)",
    },
    {
      imgSrc: Img5,
      name: "Prof. Siddika Surse",
      designation: "(Faculty of VFX and Animation))",
    },
    {
      imgSrc: Img4,
      name: "Prof. Kalyani Shinde",
      designation: "(Faculty of film editing)",
    },
    {
      imgSrc: Img6,
      name: "Mr. Mahesh Harbak",
    },
  ];

  return (
    <>
      <section className="faculty pt-100">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h2 className="sec-title pt-0 mt-0 mb-3 animation__char_come text-center">
                <Reveal bottom> Faculty</Reveal>
              </h2>
            </div>
            {facultyMembers.map((member, index) => (
              <div
                className="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-6 mx-auto"
                key={index}
              >
                <Link to="/faculty">
                  <LazyLoadImage
                    src={member.imgSrc}
                    className="img-fluid"
                    alt="Faculty Member"
                  />
                  <div className="team__info text-center">
                    <h4 className="team__member-name-6 text-center">
                      {member.name}
                    </h4>
                    <p>{member.designation}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
