import React from "react";
import { Header } from "../../../Components/Common/Header";
// import bg from "../../../assets/imgs/bg/film-direction.png";
import {
  ButtonMAinFMBrochure,
  ButtonMAinFMSyllabus,
} from "../../../Components/Common/Button/Button";
import { Link } from "react-router-dom";
export const MainFilmMaking = () => {
  return (
    <>
      <Header />
      <main>
        {/* <section className="job__detail-top job__detail-top-film-direction pt-150 pb-100">
          <div className="container"> 
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title mt-5 sub-section-title animation__char_come mt-5 text-white">
                    MA IN FILMMAKING
                  </h2>
                  <p className="text-white">
                    Learn Filmmaking in a hands-on, practical, production
                    company environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="job__detail-top-film-direction pt-150 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    MA IN FILMMAKING
                  </h2>
                  <p className="text-white">
                    Learn Filmmaking in a hands-on, practical, production
                    company environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <div className="row">
              {/* <!-- <div className="text-center mt-3">
                                <h1>MA IN FILMMAKING</h1>
                            </div> --> */}
              <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-8">
                <div className="job__detail-wrapper">
                  {/* <!-- <h2 className="sec-title">Program Overview</h2> --> */}
                  {/* <!-- <ul className="job__detail-meta">
                                        <li><span>Location</span> 2541, Austin, New York</li>
                                        <li><span>Date</span> 24, June 2022</li>
                                        <li><span>Job Type</span> Full time (Remote)</li>
                                    </ul> --> */}
                  <div className="job__detail-content">
                    {/* <!-- <p>The Graduation Program Designed by Film Industry stalwarts will immerse you
                                            in every aspect of practical filmmaking, from the creative to the technical.
                                            You will learn essential industry-relevant skills from current Film and TV &
                                            Advertising Industry professionals, and gain the knowledge and experience to
                                            further your career and launch into the industry with confidence.</h2>
                                        <ul>
                                            <li>To offer the young aspiring film professionals an interdisciplinary and
                                                dynamic milieu, where they can develop a comprehensive and critical
                                                awareness of all aspects of filmmaking.</li>
                                            <li>To develop strong and independent “Film Professionals</li>
                                            <li>To provide the fundamental aesthetic values of the art of cinema</li>
                                            <li>To provide a platform for the showcase of their art.</li>
                                        </ul> --> */}
                    <h2>Program Highlights</h2>
                    <ul>
                      <li>Compulsory Short Film Production every semester</li>
                      <li>Masterclass by Renowned Directors</li>
                      <li>
                        Feature Film Production, Release, and Marketing Process
                        compulsory for every student
                      </li>
                    </ul>
                    <h2>Filmmaking Facilities</h2>
                    <p>
                      Film City Editing Studios Sound Recording &amp; Mixing
                      Studio Color Lab (DI) Preview Theatre (4K, 9.2.1 Dolby)
                      Film Cameras Cine Lights Cine Lenses Chroma Floor Shooting
                      Locations Costume Library Art Inventory VFX Lab Miniature
                      Studio
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4">
                <div className="job__detail-sidebar">
                  <h5 className="mb-3">Speak with Program In-charge</h5>
                  {/* <ul>
                    <li>
                      <span>Prof. Jaee Kadam</span> +91 8208881783
                    </li>
                  </ul> */}
                  <h5 className="mt-5 mb-3">Fees Structure</h5>
                  <ul>
                    <li>
                      <span>Duration:</span> 2 Years (4 Semesters)
                    </li>
                    <li>
                      <span>Annual Fees:</span> 1,50,000/- + University Fees
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        View More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio__service service-v5 pt-110 pb-110 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6">
                <h2 className="sec-title animation__char_come">
                  MA in Filmmaking
                </h2>
              </div>
              <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-6">
                <div className="sec-text">
                  <p>
                    Qualify with a MA in Filmmaking in 2 years. Our intensive,
                    full-time program covers all the key disciplines of
                    filmmaking.
                  </p>
                </div>
              </div>
              <p className="mb-3 mt-3">
                Students can opt for one Major out of the following
              </p>
            </div>
            <div className="portfolio__service-list">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-in-acting-for-films">
                      <h3 className="ps-title">
                        MA IN <br />
                        FILM ACTING
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-film-direction">
                      <h3 className="ps-title">
                        MA in &nbsp;
                        <br />
                        Film Direction
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-in-film-editing">
                      <h3 className="ps-title">
                        MA in <br />
                        Film Editing
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-in-sound-design-music-production-022">
                      <h3 className="ps-title">
                        MA in Sound <br />
                        &amp; MUSIC PRODUCTION
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-in-cinematography">
                      <h3 className="ps-title">
                        MA in &nbsp;
                        <br />
                        Cinematography
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-in-vfx-animation">
                      <h3 className="ps-title">
                        MA in VFX <br />
                        &amp; Animation
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-in-production-design-art-direction-2">
                      <h3 className="ps-title">
                        MA IN PRODUCTION <br />
                        &amp; ART DIRECTION
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 justify-content-center">
                  <ButtonMAinFMBrochure />
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <ButtonMAinFMSyllabus />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
