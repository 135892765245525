import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import Accordion from "react-bootstrap/Accordion";

export const DiplomaInProductionDesignAndArtDirection = () => {
  return (
    <>
      <Header />

      <main>
        <section className="job__detail-top-film-direction pt-150 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    Diploma In Production Design & Art Direction
                  </h2>
                  <p className="text-white">
                    Create easy access to the Film Industry
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title sub-section-title">
                    Diploma with Major in Production Design & Art Direction
                  </h2>
                  <div className="job__detail-content">
                    <p>
                      Are you new to filmmaking? This intensive Diploma Program
                      is the only one of its kind to provide the opportunity to
                      Design Film at Film City, Shooting Floors, Art Inventory
                      and see our film shot on Super 35mm cameras, Cine Lenses
                      and complete professional Filmmaking facilities.
                    </p>
                    <p>
                      In two semesters, you will take two short film project
                      from script to screen, gaining a complete and hands on
                      knowledge practically in the important filmmaking
                      principles and learn the cutting edge skills that equip
                      you for an entry level job in film, TV, Advertising and
                      web industry.
                    </p>
                    <p>
                      MGMU School of Film Arts is perfect echo-system for
                      budding filmmakers, actors and technicians.
                    </p>
                    <h4 className="mt-3">
                      A Film Direction student will excel in his craft only when
                      he will master in all the filmmaking elements. An Director
                      must know the camera and Lenses, Lighting Patterns,
                      Editing, Dubbing, Vfx. Hence we provide all standard
                      filmmaking facilities to the Direction student from day
                      one.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xxl-12">
                <div className="faq__list-6">
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Program Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            We follow the principle of “Learning by doing” while
                            providing In-depth, hands-on, practical training
                          </li>
                          <li>
                            Training by practising film & TV industry tutors
                          </li>
                          <li>Easy access to Dubbing, Editing Studios</li>
                          <li>
                            Training with the help of Complete Professional
                            Filmmaking Setup
                          </li>
                          <li>Masterclass by Actors from Industry</li>
                          <li>
                            Participation in compulsory classroom projects in
                            every semester
                          </li>
                          <li>
                            Chance to Design and Execute Full Length Feature
                            Film in 5th Semester as compulsory Feature Project
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Fees & Program Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">Duration</span>: 3 Years
                            (6 Semesters)
                          </li>
                          <li>
                            <span className="fw-bold">Internship</span>:
                            Compulsory for 12 weeks
                          </li>
                          <li>
                            <span className="fw-bold">Annual Fees</span>: Rs.
                            1,50,000/- + University Fees
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Syllabus</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <a href="contact-us">Click here</a> to ask for
                          syllabus
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Faculty & Mentors</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">1. Sandeep Inamke</span>{" "}
                            Production Designer, Art Director
                          </li>
                          <li>
                            <span className="fw-bold">2. Kumar Magre</span>{" "}
                            Gateup Designer
                          </li>
                          <li>
                            <span className="fw-bold">3. Shiv Kadam</span>{" "}
                            Director, Writer, Academician
                          </li>
                          <li>
                            <span className="fw-bold">4. Dhanashri Kale</span>{" "}
                            Architect
                          </li>
                          {/* <li>
                            <span className="fw-bold">5. Jaee Kadam</span>{" "}
                            Cinematographer, Executive Producer
                          </li> */}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Core Facilities</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>1. Dubbing Studio</li>
                          <li>2. Shooting Floors</li>
                          <li>3. Shooting Locations</li>
                          <li>4. Filmmaking Equipments</li>
                          <li>5. Costume Lab</li>
                          <li>6. Make-up Lab</li>
                          <li>
                            7. <a href="facilities">Click here</a> to check
                            other facilities
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="mt-5">
                  <h4 className="text-white mb-2">
                    Speak with the Program Incharge
                  </h4>
                  {/* <p>
                    <span className="fw-bold">Prof. Jaee Kadam</span> +91
                    8208881783
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
