import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import { Accordion } from "react-bootstrap";
export const MaInCinematography = () => {
  return (
    <>
      <Header />

      <main>
        <section
          className="job__detail-top-cinematography pt-150 pb-100"
          style={{
            background:
              "url(assets/imgs/bg/cinematography.jpg?v=0.1.9) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white">
                    MA In Cinematography
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper job__detail-wrapper-20">
                  <h2 className="sec-title sub-section-title">
                    MA with Major in Cinematography
                  </h2>
                  <div className="job__detail-content">
                    <p>
                      Our 2- Year MA in Filmmaking (With Cinematography Major)
                      is designed to make our student excel in Goffering
                      (Lighting), Focus Pulling and Direction of Photography for
                      Films. We offer professional film education in a practical
                      setting which is supported by our in-house studios. Our
                      teachers are industry professionals, working in film and
                      television.
                    </p>
                    <p>
                      Throughout the course you will gain a thorough knowledge
                      in the key areas of the process of Cinematography, from
                      script to screen, and become multi-skilled and adaptable,
                      favoring the art of collaborative storytelling rather than
                      working as a solo artist. You will learn how to quickly
                      adapt to the expectations of the director and that will
                      help you start and continue your career in the industry.
                    </p>
                    <p>
                      MGMU School of Film Arts is a perfect echo-system for
                      budding filmmakers, actors and technicians.
                    </p>
                    <h4 className="mt-3">
                      A Cinematographer will excel in his craft only when he
                      will master all the filmmaking elements by having easy
                      access to professional filmmaking setup. A DOP must not
                      only know the camera and Lenses, Lighting Patterns, but
                      also Editing, Dubbing, VFX. Hence we provide all standard
                      filmmaking facilities to the Cinematography student from
                      day one.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xxl-12">
                <div className="faq__list-6">
                  <Accordion defaultActiveKey="0" id="accordionExample">
                    {/* Program Highlights */}
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Program Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            We follow the principle of “Learning by doing” while
                            providing In-depth, hands-on, practical training
                          </li>
                          <li>
                            Training by practising film & TV industry tutors
                          </li>
                          <li>
                            Easy access to Super 35mm Cameras, Cine Lenses, Cine
                            Lights, etc.
                          </li>
                          <li>
                            Training with the help of Complete Professional
                            Filmmaking Setup
                          </li>
                          <li>Masterclass by Cinematographers from Industry</li>
                          <li>
                            Participation in compulsory classroom projects in
                            every semester
                          </li>
                          <li>
                            Chance to do Cinematography of Full Length Feature
                            Film in 5th Semester as compulsory Feature Project
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Fees & Program Details */}
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Fees & Program Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">Duration</span>: 2 Years
                            (4 Semesters)
                          </li>
                          <li>
                            <span className="fw-bold">Eligibility</span>: Any
                            Graduate
                          </li>
                          <li>
                            <span className="fw-bold">Internship</span>:
                            Compulsory for 12 weeks
                          </li>
                          <li>
                            <span className="fw-bold">Annual Fees</span>: Rs.
                            1,50,000 + University Fees
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Syllabus */}
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Syllabus</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <a href="contact-us">Click here</a> to ask for
                          syllabus
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Student Feedback */}
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Student Feedback</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          TO BE A FILMMAKER, YOU HAVE TO BE PSYCHOTIC IN YOUR
                          DESIRE TO DO SOMETHING. PEOPLE ALWAYS LIKE THE EASY
                          ROUTE. YOU HAVE TO PUSH VERY HARD TO GET SOMETHING
                          UNUSUAL, SOMETHING DIFFERENT. A FILM IS NEVER REALLY
                          GOOD UNLESS THE CAMERA IS AN EYE OF FILM DIRECTOR. A
                          Filmmaker visualizes his story from a camera's point
                          of view. He wants the DOP /Cinematographer to
                          understand his vision, the way he wants to tell the
                          story, and include all the technicalities which will
                          support the director in narrating his story. AND ALL
                          THIS TECHNIQUE AND THEORY ARE PRACTICE AND STUDIED
                          HERE IN MGM FILM SCHOOL with all the required
                          equipment for preproduction and post-production of a
                          film AND WELL TAUGHT by OUR MENTORS.AS I'm a
                          CINEMATOGRAPHY student my cinematography mentors are
                          very supportive and helping with the experience of
                          many years of the film industry.
                        </p>
                        <p>
                          Milind Jog is an Indian cinematographer who primarily
                          works in the Marathi film industry. After making his
                          debut with Restaurant (2006), his other popular films
                          include Nirop, Vazandar: Biggie, Bhagtos Kay Mujra
                          Kar, Muramba, Faster Fene, Aamhi Doghi, and
                          Girlfriend.
                        </p>
                        <p>
                          Milind jog sir is a very creative person and clam and
                          patience in his work and while he's working with us,
                          he taught us many creative ideas for frame
                          compositions and many more. As being a
                          cinematographer, we have to light our subjects in the
                          frame to create perfect mood and impact for our scene.
                          But cutting the light is more important than lighting
                          the subject which Milind sir taught us from THE
                          BASICS.
                        </p>
                        <p>
                          Veerdhaval Patil is a cinematographer who primarily
                          works in the Marathi film and television industry.
                          Making his debut with the 2019 movie Babo. In the 62nd
                          National Award Ceremony, the film KHWADA received two
                          awards; 'Special Jury Mention' and 'Sync Sound'. Which
                          were shot by him.
                        </p>
                        <p>
                          We had a very tremendous experience with Veerdhval
                          Patil sir they taught us many things about lenses,
                          light cutting, framing, creating source lighting, and
                          bouncing light He's taught us about the light ratio
                          and how to give a perfect amount of light to subjects
                          by the uses of all our available equipment's and also
                          taught the lenses in detail, which lense give what
                          kind of impact and what kind of frame can be created
                          by using different types of lenses. Sir also taught us
                          about the focus pulling methods which has proved very
                          helpful for us. And he also took our session by film
                          appreciation. He showed us many films regarding
                          cinematography, shot composition and lighting,etc.
                        </p>
                        <p>MOHIT BHAMRE</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Faculty & Mentors */}
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Faculty & Mentors</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">1. Mahesh Aney </span>
                            Cinematographer
                          </li>
                          <li>
                            <span className="fw-bold">2. Milind Jog </span>
                            Cinematographer
                          </li>
                          <li>
                            <span className="fw-bold">3. Veerdhaval Patil</span>
                            Cinematographer
                          </li>
                          <li>
                            <span className="fw-bold">4. Karan Tandale </span>
                            Director, Cinematographer
                          </li>
                          <li>
                            <span className="fw-bold">5. Shiv Kadam </span>
                            Director, Writor, Academician
                          </li>
                          <li>
                            <span className="fw-bold">
                              6. Satyajit Shobha Shriram
                            </span>
                            Cinematographer
                          </li>
                          {/* <li>
                            <span className="fw-bold">7. Jaee Kadam </span>
                            Cinematographer, Executive Producer
                          </li> */}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Core Facilities */}
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Core Facilities</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>1. Dubbing Studio</li>
                          <li>2. Shooting Floors</li>
                          <li>3. Shooting Locations</li>
                          <li>4. Filmmaking Equipments</li>
                          <li>5. Costume Lab</li>
                          <li>6. Make-up Lab</li>
                          <li>
                            7. <a href="facilities">Click here</a> to check
                            other facilities
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="mt-5">
                  <h4 className="text-white mb-2">
                    Speak with the Program Incharge
                  </h4>
                  {/* <p>
                    <span className="fw-bold">Prof. Jaee Kadam </span>{" "}
                    8208881783 / 9699039209
                  </p>
                  <p>jkadam@mgmu.ac.in</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
