import React, { useState, useEffect } from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "../../assets/css/ImageSlider.css";
import "../../assets/css/master.css";
import Img1 from "../../assets/imgs/team/mentors/1.jpg";
import Img2 from "../../assets/imgs/team/mentors/2.jpg";
import Img3 from "../../assets/imgs/team/mentors/3.jpg";
import Img4 from "../../assets/imgs/team/mentors/4.jpg";
import Img6 from "../../assets/imgs/team/mentors/6.jpg";
import Img7 from "../../assets/imgs/team/mentors/7.jpg";
import Img8 from "../../assets/imgs/team/mentors/8.jpg";
import Thumb1 from "../../assets/imgs/thumb/1.png";
import Thumb2 from "../../assets/imgs/thumb/2.png";
import Thumb3 from "../../assets/imgs/thumb/3.png";
import Thumb4 from "../../assets/imgs/thumb/4.png";

import NextIcon from "../../assets/imgs/icon/next.png";
import PrevIcon from "../../assets/imgs/icon/prev.png";
import { Link } from "react-router-dom";
import { getHeight } from "rsuite/esm/DOMHelper";
SwiperCore.use([Autoplay, Navigation, Pagination]);

const ImageSlider = () => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext(500);
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev(500);
    }
  };

  const breakpoints = {
    50: {
      slidesPerView: 1,
    },
    350: {
      slidesPerView: 2,
    },
    500: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 3,
    },
    704: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 4,
    },
    1000: {
      slidesPerView: 6,
    },
    1200: {
      slidesPerView: 7,
    },
    2400: {
      slidesPerView: 7,
    },
  };

  return (
    <>
      <div style={{ height: 450 }}>
        <div className="swiper-wrapper">
          <Swiper
            spaceBetween={50}
            slidesPerView={7}
            //    navigation
            loop
            autoplay={{ delay: 3000 }}
            effect="fade"
            fadeEffect={{ crossFade: true }}
            onSwiper={setSwiper}
            breakpoints={breakpoints}
          >
            <SwiperSlide className="odd-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img1} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">
                      Bishwadeep Chatterjee
                    </h4>
                    <h5 className="team__member-role-6">
                      Bishwadeep Chatterjee is an Indian film sound designer,
                      sound editor and audio mixer.
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="even-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img2} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6"> Dharam Gulati</h4>
                    <h5 className="team__member-role-6">
                      Dharam Gulati is known for Muigwithania (2010), Project
                      103 and Hum Farishte Nahin (1988)
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="odd-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img3} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">Mahesh Aney</h4>
                    <h5 className="team__member-role-6">
                      Is a Film &amp; Television Institute of India (FTII)
                      graduate
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="even-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img4} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">
                      Chandrakant Kulkarni
                    </h4>
                    <h5 className="team__member-role-6">
                      Is a Marathi theatre &amp; film director, script-writer,
                      &amp; actor
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="odd-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img6} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">Makarand Anaspure</h4>
                    <h5 className="team__member-role-6">
                      Anaspure directed the Marathi film Dambis, in 2011
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="even-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img7} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">Yateen Karyekar</h4>
                    <h5 className="team__member-role-6">
                      Is a veteran Indian actor in the Bollywood film industry
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="odd-slide">
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img8} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">Mukta Barve</h4>
                    <h5 className="team__member-role-6">
                      Is a Indian film, television, &amp; theatre actor &amp;
                      producer
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="even-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img1} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">
                      Bishwadeep Chatterjee
                    </h4>
                    <h5 className="team__member-role-6">
                      Bishwadeep Chatterjee is an Indian film sound designer,
                      sound editor and audio mixer.
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="odd-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img2} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6"> Dharam Gulati</h4>
                    <h5 className="team__member-role-6">
                      Dharam Gulati Dharam Gulati is known for Muigwithania
                      (2010), Project 103 and Hum Farishte Nahin (1988).
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="even-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img3} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">Mahesh Aney</h4>
                    <h5 className="team__member-role-6">
                      Is a Film &amp; Television Institute of India (FTII)
                      graduate
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="odd-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img4} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">
                      Chandrakant Kulkarni
                    </h4>
                    <h5 className="team__member-role-6">
                      Is a Marathi theatre &amp; film director, script-writer,
                      &amp; actor
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="even-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img6} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">Makarand Anaspure</h4>
                    <h5 className="team__member-role-6">
                      Anaspure directed the Marathi film Dambis, in 2011
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="odd-slide">
              {" "}
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img7} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">Yateen Karyekar</h4>
                    <h5 className="team__member-role-6">
                      Is a veteran Indian actor in the Bollywood film industry
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide className="even-slide">
              <div className="swiper-slide team__slide">
                <Link to="/faculty">
                  <img className="slider-img" src={Img8} alt="Team Member" />
                  <div className="team__info">
                    <h4 className="team__member-name-6">Mukta Barve</h4>
                    <h5 className="team__member-role-6">
                      Is a Indian film, television, &amp; theatre actor &amp;
                      producer
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>

          <div className="mentor-nav">
            <div className="container">
              <div className="row">
                <div className="col-6 text-start" onClick={goPrev}>
                  <img src={PrevIcon} className="prev-btn swipper-btn" alt="" />
                </div>
                <div className="col-6 text-end" onClick={goNext}>
                  <img src={NextIcon} className="next-btn swipper-btn" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button onClick={goPrev}>Previous</button>
        <button onClick={goNext}>Next</button> */}
    </>
  );
};

export default ImageSlider;

SwiperCore.use([Autoplay, Navigation, Pagination]);

export const ImageSlider2 = () => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext(2000);
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev(2000);
    }
  };

  const testimonials = [
    {
      imgSrc: Thumb1,
      feedback: "Editor",
      p: "Hello myself Sagar Gayke,",
      description:
        "I am a student of MGM School of Film Arts. I completed my graduation in B.A (hons) film making with the Editing specialization. Now I am working as a freelancing editor.",
      name: "Sagar Gayake",
    },
    {
      imgSrc: Thumb2,
      feedback: "Director",
      description:
        "Sangita Bera is an alumna of MGM School of Film Arts. She completed her diploma in Direction in 2022. She was a part of projects like 'Daridry Reshe Khali' and 'Pachola'. Her 1st short film '13 Hours' was selected in Ajanta Ellora International Film Festivals.",
      name: "Sangeeta Bera",
      email: "sangitabera1995@gmail.com",
    },
    {
      imgSrc: Thumb3,
      feedback: "Sound Designer",
      description: " ",
      name: "Prajwal Marmat",
    },
    {
      imgSrc: Thumb4,
      feedback: "Director",
      description: " ",
      name: "Nitin Nikale",
    },
  ];

  const breakpoints = {
    360: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 1,
    },
    1000: {
      slidesPerView: 1,
    },
    1200: {
      slidesPerView: 2,
    },
    1500: {
      slidesPerView: 2,
    },
    2400: {
      slidesPerView: 2,
    },
  };

  return (
    <>
      <div className="swiper-wrapper">
        <Swiper
          spaceBetween={20}
          slidesPerView={2}
          loop
          effect="fade"
          fadeEffect={{ crossFade: true }}
          onSwiper={setSwiper}
          breakpoints={breakpoints}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide
              className={index % 2 === 0 ? "even-slide" : "odd-slide"}
              key={index}
            >
              <div className="swiper-slide testimonial__slide-4">
                <img
                  className="testimonial__img-4"
                  src={testimonial.imgSrc}
                  alt="Testimonial Image"
                />
                <div className="testimonial__info-4">
                  <h4 className="testimonial__feedback-4">
                    {testimonial.feedback}
                  </h4>
                  <p>
                    {testimonial.p}
                    <br />
                    {testimonial.description}
                  </p>
                  <h5 className="testimonial__name-4">{testimonial.name}</h5>
                  {testimonial.email && (
                    <Link
                      to={`mailto:${testimonial.email}`}
                      className="text-white"
                    >
                      {testimonial.email}
                    </Link>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="story-nav">
          <div className="row">
            <div onClick={goPrev} className="col-6 text-start ps-5">
              <img src={PrevIcon} className="prev-button swipper-btn" alt="" />
            </div>
            <div onClick={goNext} className="col-6 text-end">
              <img src={NextIcon} className="next-button swipper-btn" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
SwiperCore.use([Autoplay, Navigation, Pagination]);

export const ImageSlider3 = () => {
  const [swiper, setSwiper] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const openModal = (index) => {
    setIsModalOpen(true);
    setActiveSlideIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext(2000);
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev(2000);
    }
  };

  const testimonials = [
    {
      imgSrc: require("../../assets/imgs/gallery/01.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/02.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/03.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/04.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/05.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/06.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/07.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/08.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/09.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/10.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/11.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/12.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/13.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/14.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/15.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/16.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/17.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/18.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/19.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/20.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/21.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/22.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/23.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/24.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/25.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/26.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/27.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/28.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/29.JPG"),
    },
    {
      imgSrc: require("../../assets/imgs/gallery/30.JPG"),
    },
  ];

  const breakpoints = {
    360: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 1,
    },
    1000: {
      slidesPerView: 1,
    },
    1200: {
      slidesPerView: 1,
    },
    1500: {
      slidesPerView: 1,
    },
    2400: {
      slidesPerView: 1,
    },
  };

  return (
    <>
      <Swiper
        spaceBetween={20}
        slidesPerView={2}
        loop
        effect="fade"
        fadeEffect={{ crossFade: true }}
        onSwiper={setSwiper}
        breakpoints={breakpoints}
        autoplay={{ delay: 1000, disableOnInteraction: false }}
        speed={1200} // Set the transition speed to 500ms (0.5 seconds)
        ease="ease" // Use the "ease" easing function for smoother transition
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide className="gallery__thumb " key={index}>
            <img
              className=""
              src={testimonial.imgSrc}
              alt="Testimonial Image"
              style={{ width: "100%", maxWidth: "800px" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="story-nav">
        <div className="row">
          <div onClick={goPrev} className="col-6 text-start ">
            <img src={PrevIcon} className="prev-button swipper-btn" alt="" />
          </div>
          <div onClick={goNext} className="col-6 text-end ">
            <img src={NextIcon} className="next-button swipper-btn" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export const ImageSlider4 = () => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext(2000);
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev(2000);
    }
  };

  const testimonials = [
    {
      imgSrc: Thumb3,
      email: "shelkesushma99@gmail.com",
      mobile: "7498049860",
      description:
        "I am an aspiring actor. I was clear about making my career in Film Industry only. At MGM Film Arts I came to know about the difference between acting for theatre and acting for films. Here I am not only studying acting theories but we are also able to develop our skills as an actor with professional film making facility. I am so excited to share that a professional sound recording studio is available from day  one for the acting students. Such studio is very much essential to learn dubbing skills. As a student of acting I have acted in near about 10 short feature films. I am happy to share that I am playing lead character in the department's irst full length feature film.",
      name: "SushmaShelke",
    },
    {
      imgSrc: Thumb3,
      email: "sharma.arnav.aaa@gmail.com",
      mobile: "9910343340",
      description:
        "It has already been 8 months since I left Delhi and I can't believe that time flies so quickly. I feel at home here in MGM. Although language was a big issue at first as I belong to northern culture but people here are so friendly that I didn't get any problem to adjust with my fellow mates. Coming to the academic support, as an aspiring cinematographer, MGM film arts has given me so much, a filmmaker can't ask for more. Here in film arts, students have all the professional tools for learning which we get whenever we want. We have a director himself to teach filmmaking, Mr Shiv kadam HOD, film arts. Who helps each and every student personally during shoots. For me personally, Shiv sir is the best mentor I could ever get. The process of learning here is unique which I personally like, as filmmaking is more practical than it is theoretical, so film arts mostly concentrates on the practical work which I think is the most effective way to excel in the field. In 8 months I made almost 5 short films, in which one of them got direct exposure to AIFF (Aurangabad international film festival) which added onto my showreel. All in all, film arts is a full package of learning filmmaking effectively and I feel proud to be a part of such a great institute. It was such a great experience learning here. Thank you MGM university.",
      name: "Arnav Sharma",
    },
    {
      imgSrc: Thumb3,
      email: "shelkesushma99@gmail.com",
      mobile: "7498049860",
      description:
        "I am an aspiring actor. I was clear about making my career in Film Industry only. At MGM Film Arts I came to know about the difference between acting for theatre and acting for films. Here I am not only studying acting theories but we are also able to develop our skills as an actor with professional film making facility. I am so excited to share that a professional sound recording studio is available from day  one for the acting students. Such studio is very much essential to learn dubbing skills. As a student of acting I have acted in near about 10 short feature films. I am happy to share that I am playing lead character in the department's irst full length feature film.",
      name: "SushmaShelke",
    },
    {
      imgSrc: Thumb3,
      email: "sharma.arnav.aaa@gmail.com",
      mobile: "9910343340",
      description:
        "It has already been 8 months since I left Delhi and I can't believe that time flies so quickly. I feel at home here in MGM. Although language was a big issue at first as I belong to northern culture but people here are so friendly that I didn't get any problem to adjust with my fellow mates. Coming to the academic support, as an aspiring cinematographer, MGM film arts has given me so much, a filmmaker can't ask for more. Here in film arts, students have all the professional tools for learning which we get whenever we want. We have a director himself to teach filmmaking, Mr Shiv kadam HOD, film arts. Who helps each and every student personally during shoots. For me personally, Shiv sir is the best mentor I could ever get. The process of learning here is unique which I personally like, as filmmaking is more practical than it is theoretical, so film arts mostly concentrates on the practical work which I think is the most effective way to excel in the field. In 8 months I made almost 5 short films, in which one of them got direct exposure to AIFF (Aurangabad international film festival) which added onto my showreel. All in all, film arts is a full package of learning filmmaking effectively and I feel proud to be a part of such a great institute. It was such a great experience learning here. Thank you MGM university.",
      name: "Arnav Sharma",
    },
  ];

  const breakpoints = {
    360: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 1,
    },
    1000: {
      slidesPerView: 1,
    },
    1200: {
      slidesPerView: 2,
    },
    1500: {
      slidesPerView: 2,
    },
    2400: {
      slidesPerView: 2,
    },
  };

  return (
    <>
      <div className="swiper-wrapper my-3">
        <Swiper
          spaceBetween={20}
          slidesPerView={2}
          loop
          effect="fade"
          fadeEffect={{ crossFade: true }}
          onSwiper={setSwiper}
          breakpoints={breakpoints}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide
              className={index % 2 === 0 ? "even-slide" : "odd-slide"}
              key={index}
            >
              <div className="swiper-slide testimonial__slide-4 text-align-left">
                <img
                  className="testimonial__img-4"
                  src={testimonial.imgSrc}
                  alt="Testimonial Image"
                />
                <div className="testimonial__info-4">
                  {/* <h4 className="testimonial__feedback-4">
                    {testimonial.feedback}
                  </h4> */}
                  <p style={{ textAlign: "left" }}>{testimonial.description}</p>
                  <h5
                    style={{ textAlign: "left" }}
                    className="testimonial__name-4"
                  >
                    {testimonial.name}
                  </h5>
                  <h5 style={{ textAlign: "left" }}>
                    {" "}
                    {testimonial.email && (
                      <Link
                        to={`mailto:${testimonial.email}`}
                        className="text-white"
                      >
                        {testimonial.email}
                      </Link>
                    )}
                  </h5>
                  <h5
                    style={{ textAlign: "left" }}
                    className="testimonial__name-4"
                  >
                    {testimonial.mobile}
                  </h5>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="story-nav">
          <div className="row">
            <div onClick={goPrev} className="col-6 text-start ps-5">
              <img src={PrevIcon} className="prev-button swipper-btn" alt="" />
            </div>
            <div onClick={goNext} className="col-6 text-end">
              <img src={NextIcon} className="next-button swipper-btn" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SwiperCore.use([Autoplay, Navigation, Pagination]);

export const ImageSlider5 = () => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext(2000);
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev(2000);
    }
  };

  const testimonials = [
    {
      imgSrc: require("../../assets/imgs/national-film-awards/01.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/02.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/03.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/04.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/05.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/06.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/07.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/08.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/09.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/10.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/national-film-awards/11.jpg"),
    },
  ];

  const breakpoints = {
    360: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 1,
    },
    1000: {
      slidesPerView: 1,
    },
    1200: {
      slidesPerView: 1,
    },
    1500: {
      slidesPerView: 1,
    },
    2400: {
      slidesPerView: 1,
    },
  };

  return (
    <>
      <div className="swiper-wrapper " style={{ marginBottom: "50px" }}>
        <Swiper
          spaceBetween={20}
          slidesPerView={2}
          loop
          effect="fade"
          fadeEffect={{ crossFade: true }}
          onSwiper={setSwiper}
          modules={[EffectFade]}
          autoplay={{ delay: 4500 }}
          speed={1500}
          breakpoints={breakpoints}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide
              className={index % 2 === 0 ? "even-slide" : "odd-slide"}
              key={index}
            >
              <img
                className=" "
                style={{ maxWidth: "100%" }}
                src={testimonial.imgSrc}
                alt="Testimonial Image"
              />
              {/* <div className="swiper-slide testimonial__slide-4">
               
              </div> */}
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="story-nav">
          <div className="row">
            <div onClick={goPrev} className="col-6 text-start ps-5">
              <img src={PrevIcon} className="prev-button swipper-btn" alt="" />
            </div>
            <div onClick={goNext} className="col-6 text-end">
              <img src={NextIcon} className="next-button swipper-btn" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const ImageSlider6 = () => {
  const [swiper, setSwiper] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const openModal = (index) => {
    setIsModalOpen(true);
    setActiveSlideIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext(2000);
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev(2000);
    }
  };

  const testimonials = [
    {
      imgSrc: require("../../assets/imgs/aiff-9/1.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/2.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/3.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/4.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/5.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/6.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/7.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/8.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/9.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/10.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/11.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/12.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/13.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/14.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/15.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/16.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/17.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/18.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/19.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/20.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/21.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/22.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/23.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/24.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/25.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/26.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/27.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/28.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/29.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/20.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/31.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/32.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/33.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/34.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/35.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/36.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/37.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/38.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/39.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/30.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/41.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/42.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/43.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/44.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/45.jpg"),
    },
    {
      imgSrc: require("../../assets/imgs/aiff-9/46.jpg"),
    },
  ];

  const breakpoints = {
    360: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 1,
    },
    1000: {
      slidesPerView: 1,
    },
    1200: {
      slidesPerView: 1,
    },
    1500: {
      slidesPerView: 1,
    },
    2400: {
      slidesPerView: 1,
    },
  };

  return (
    <>
      <Swiper
        spaceBetween={20}
        slidesPerView={2}
        loop
        effect="fade"
        fadeEffect={{ crossFade: true }}
        onSwiper={setSwiper}
        breakpoints={breakpoints}
        autoplay={{ delay: 1000, disableOnInteraction: false }}
        speed={1200} // Set the transition speed to 500ms (0.5 seconds)
        ease="ease" // Use the "ease" easing function for smoother transition
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide className="gallery__thumb " key={index}>
            <a href="https://shaats.net/logos/aiff.pdf" target="_blank">
              <img
                className=""
                src={testimonial.imgSrc}
                alt="Testimonial Image"
                style={{ width: "100%", maxWidth: "800px" }}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="story-nav">
        <div className="row">
          <div onClick={goPrev} className="col-6 text-start ">
            <img src={PrevIcon} className="prev-button swipper-btn" alt="" />
          </div>
          <div onClick={goNext} className="col-6 text-end ">
            <img src={NextIcon} className="next-button swipper-btn" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
// export const ImageSlider6 = () => {
//   const [swiper, setSwiper] = useState(null);

//   const goNext = () => {
//     if (swiper !== null) {
//       swiper.slideNext(2000);
//     }
//   };

//   const goPrev = () => {
//     if (swiper !== null) {
//       swiper.slidePrev(2000);
//     }
//   };

//   const testimonials = [
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/1.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/2.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/3.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/4.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/5.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/6.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/7.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/8.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/9.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/10.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/11.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/12.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/13.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/14.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/15.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/16.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/17.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/18.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/19.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/20.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/21.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/22.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/23.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/24.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/25.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/26.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/27.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/28.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/29.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/20.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/31.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/32.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/33.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/34.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/35.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/36.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/37.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/38.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/39.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/30.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/41.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/42.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/43.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/44.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/45.jpg"),
//     },
//     {
//       imgSrc: require("../../assets/imgs/aiff-9/46.jpg"),
//     },
//   ];

//   const breakpoints = {
//     360: {
//       slidesPerView: 1,
//     },
//     640: {
//       slidesPerView: 1,
//     },
//     1000: {
//       slidesPerView: 1,
//     },
//     1200: {
//       slidesPerView: 1,
//     },
//     1500: {
//       slidesPerView: 1,
//     },
//     2400: {
//       slidesPerView: 1,
//     },
//   };

//   return (
//     <>
//       <div className="swiper-wrapper " style={{ marginBottom: "50px" }}>
//         <Swiper
//           spaceBetween={20}
//           slidesPerView={2}
//           loop
//           effect="fade"
//           fadeEffect={{ crossFade: true }}
//           onSwiper={setSwiper}
//           modules={[EffectFade]}
//           autoplay={{ delay: 4500 }}
//           speed={1500}
//           breakpoints={breakpoints}
//         >
//           {testimonials.map((testimonial, index) => (
//             <SwiperSlide
//               className={index % 2 === 0 ? "even-slide" : "odd-slide"}
//               key={index}
//             >
//               <img
//                 className=" "
//                 style={{ maxWidth: "100%" }}
//                 src={testimonial.imgSrc}
//                 alt="Testimonial Image"
//               />
//               {/* <div className="swiper-slide testimonial__slide-4">

//               </div> */}
//             </SwiperSlide>
//           ))}
//         </Swiper>

//         <div className="story-nav">
//           <div className="row">
//             <div onClick={goPrev} className="col-6 text-start ps-5">
//               <img src={PrevIcon} className="prev-button swipper-btn" alt="" />
//             </div>
//             <div onClick={goNext} className="col-6 text-end">
//               <img src={NextIcon} className="next-button swipper-btn" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
