export const facultyData = [
    {
      id: 1,
      name: "Vishwajeet Joshi",
      role: "Is a leading Music Composer in Marathi Film Industry",
      image: require("../imgs/team/faculty/1.jpg"),
    },
    {
      id: 2,
      name: "Avinash Chandrachood",
      role: "Is a Marathi film composer",
      image: require("../imgs/team/faculty/2.jpg"),
    },
    {
      id: 3,
      name: "Dnyanesh Zoting",
      role: "Is writer, and director",
      image: require("../imgs/team/faculty/3.jpg"),
    },
    {
      id: 4,
      name: "Jayprad Desai",
      role: "Is a creator of Web Series Hutatma",
      image: require("../imgs/team/faculty/4.jpg"),
    },
    {
      id: 5,
      name: "Milind Jog",
      role: "Is an Indian cinematographer who primarily works in the Marathi film industry",
      image: require("../imgs/team/faculty/5.jpg"),
    },
    {
      id: 6,
      name: "Ginnilal Salunke",
      role: "Is a Fine Arts Graduate. He has worked on 45 Hindi films",
      image: require("../imgs/team/faculty/6.jpg"),
    },
    {
      id: 7,
      name: "Veerdhawal Patil",
      role: "Is a cinematographer who primarily works in the Marathi film industry",
      image: require("../imgs/team/faculty/7.jpg"),
    },
    {
      id: 8,
      name: "Karan Tandale",
      role: "Is an Indian cinematographer",
      image: require("../imgs/team/faculty/8.jpg"),
    },
    {
      id: 9,
      name: "Sandeep Inamke",
      role: "Is a noted Indian art director and production designer of Indian cinema",
      image: require("../imgs/team/faculty/9.jpg"),
    },
    {
      id: 10,
      name: "Sunny Sharma",
      role: "Has completed a Professional in Multimedia Diploma in 2003",
      image: require("../imgs/team/faculty/10.jpg"),
    },
    {
      id: 11,
      name: "Sahil Kulkarni",
      role: "Is a music director, singer, and performer of Indian cinema",
      image: require("../imgs/team/faculty/11.jpg"),
    },
    // {
    //   id: 12,
    //   name: "Jaee Kadam",
    //   role: "Completed her PG Diploma in cinematography in 2002",
    //   image: require("../imgs/team/faculty/12.jpg"),
    // },
    {
      id: 13,
      name: "Sampada Gejji",
      role: "Is a multi-faceted person who has worked in the field of entertainment including Theatre",
      image: require("../imgs/team/faculty/13.jpg"),
    },
    {
      id: 14,
      name: "Shiv Kadam",
      role: "Is a Gold Medalist of Dramatics from Dr. Babasaheb Ambedkar Marathwada University",
      image: require("../imgs/team/faculty/14.jpg"),
    },
    {
      id: 15,
      name: "Yogita Mahajan",
      role: "Seasoned theatre professional and research scholar",
      image: require("../imgs/team/faculty/15.jpg"),
    },
    {
      id: 16,
      name: "Kalyani Shinde",
      role: "She is our first batch alumni who then joined MGM School of film Arts as an Editing program coordinator",
      image: require("../imgs/team/faculty/16.jpg"),
    },
    {
      id: 17,
      name: "Sanket Dhotkar",
      role: "Is a Sound Designer and Film Mixing Engineer of Indian cinema",
      image: require("../imgs/team/faculty/17.jpg"),
    },
    // Add more faculty members here...
  ];